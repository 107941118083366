import * as React from "react"
import { Link, type HeadFC, type PageProps } from "gatsby"
import { graphql } from "gatsby"

import Headers from '../components/Headers'
import Layout from "../components/Layout"
import { dateOptions } from "./blog"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

export const query = graphql`
query BlogPostQuery($slug: String!, $lang: String!) {
    markdownRemark(fields: {
            slug: { eq: $slug },
            lang: { eq: $lang }
        }) {
        frontmatter {
            title
            date
            author
            image {
                childImageSharp {
                    gatsbyImageData(width: 800)
                }
            }
        }
        html
        timeToRead
        excerpt(format: HTML, pruneLength: 150, truncate: true)
    }
}
`

const IndexPage = ({data, pageContext}: any) => {
  data = data.markdownRemark
  //const date = new Date(data.frontmatter.date).toLocaleDateString(pageContext.lang, dateOptions)
  const backLinkLabel = pageContext.lang == 'de' ? 'Zurück' : 'Back'
  const image = getImage(data.frontmatter.image?.childImageSharp.gatsbyImageData)
  const avatar = getImage(data.frontmatter.avatar?.childImageSharp.gatsbyImageData)
  const timeToReadLabel = pageContext.lang == 'de' ? `Lesezeit: ${data.timeToRead} min` : `Reading time: ${data.timeToRead} min`
  const authorLabel = pageContext.lang == 'de' ? 'von' : 'by'

  return (
    <Layout lang={pageContext.lang}>

        { image &&
            <GatsbyImage
              image={image}
              alt={data.frontmatter.title}
              style={{width: '100%', maxHeight: 200}}
              />
        }
        {!image && <StaticImage
              src={`../images/blog/placeholder.jpeg`}
              alt="Blog Post"
              placeholder="blurred"
              width={800}
              style={{width: '100%'}}
        />}

        <div className="articleContainer">
            <div style={{display: 'flex', flexDirection: 'column'}}>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div className="timeToRead">{timeToReadLabel}</div>
            </div>

            <article dangerouslySetInnerHTML={{__html: data.html}} />

            <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: 10}}>
                { avatar &&
                <GatsbyImage
                    image={avatar}
                    alt={data.frontmatter.author}
                    style={{borderRadius: '50%', marginBottom: 20, width: 50, height: 50}}
                />
                }
                {/*<div className="author">{authorLabel} {data.frontmatter.author}</div>*/}
            </div>

          <Link to="..">&laquo; {backLinkLabel}</Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    let description = data.markdownRemark.excerpt
    // remove <h1>*</h1> from description
    description = description.replace(/<h1>(.*)<\/h1>/, '')
    //remove html tags
    description = description.replace(/<[^>]*>/g, '')
    // remove newlines
    description = description.replace(/\n/g, '')

    return <Headers
        lang={pageContext.lang}
        title={data.markdownRemark.frontmatter.title}
        description={description}
        location={location}
        />
}
